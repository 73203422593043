// Documentation: https://swiperjs.com/swiper-api
// Exemple de slider dans dtg-dentistes
// |--------------------------------------------------------------------------
// | Événements Accueil
// |--------------------------------------------------------------------------
// |
// | Fonction contenant le tout le nécéssaire pour faire fonctionner le swiper des
// | événements de l'accueil.
// |
// | IMPORTS _____
// | -> jQuery
// |
// | OPTIONS _____
// | container => Le bloc des événements de l'accueil.
// | slider => Le sélecteur du 'slider'.
// | slide => Le sélecteur des 'slide'.
// | navigation => Le sélecteur du container contenant 'current', 'total' 
// |               et la navigation.
// | prev => Le sélecteur de 'prev' (pour aller à la slide précédente).
// | next => Le sélecteur de 'next' (pour aller à la slide suivante).
// | close => Le sélecteur du bouton 'close' (pour masquer la bannière).
// | showClass => La classe permettant d'afficher le 'container' et les
// |              'navigation'.
// | show0 => Permet d'afficher un 0 devant 'current' et 'total' en bas de
// |          10.
// |--------------------------------------------------------------------------

// Importation ---------------------------------------------------------------
import Swiper from 'swiper/bundle'
// ---------------------------------------------------------------------------


export function swiperHomeEvents(options = {}) {

  // Déclaration des propriétés par défaut
  options.container === undefined ? options.container = '[data-swiper="home-events-container"]' : options.container
  options.slider === undefined ? options.slider = '[data-swiper="home-events"]' : options.slider
  options.slide === undefined ? options.slide = '[data-swiper="home-events-slide"]' : options.slide
  options.navigation === undefined ? options.navigation = '[data-swiper="home-events-navigation"]' : options.navigation
  options.prev === undefined ? options.prev = '[data-swiper="home-events-prev"]' : options.prev
  options.next === undefined ? options.next = '[data-swiper="home-events-next"]' : options.next

  // Déclarations des variables
  let container = document.querySelector(options.container)

  // Si la page contient le container home-events
  if (typeof (container) != 'undefined' && container != null) {

    // Si il y a plus qu'une slide
    if (document.querySelectorAll(options.slide).length > 1) {

      // Création du slider
      // window.addEventListener('load', () => {
        window["swiper-home-events"] = new Swiper(options.slider, {
          threshold: 10,
          loop: true,
          speed: 500,
          slidesPerView: "auto",
          spaceBetween: 40,
          autoplay: {
            delay: 7000,
          },
          navigation: {
            prevEl: options.prev,
            nextEl: options.next
          },
          breakpoints: {
            900: {
              spaceBetween: 80,
            },
            700: {
              spaceBetween: 60,
            },
            600: {
              spaceBetween: 50,
            },
          }
        })
      // }, false)
    }
  }
}