// |--------------------------------------------------------------------------
// | Back to top and links
// |--------------------------------------------------------------------------
// |
// | Fonction contenant le tout le nécéssaire pour faire fonctionner un bloc
// | sticky du back to top.
// |
// | IMPORTS _____
// | -> Observer
// | -> clickToScrollToBlock
// |
// | OPTIONS _____
// | container => Le container sticky.
// | backToTopSelector => Le sélecteur de l'élément pour retourner en haut.
// | distance => La distance en pixels avant que le contenu invisible soit
// |             visible.
// | showClass => La classe ajoutée pour rendre visible le contenu caché.
// |--------------------------------------------------------------------------

// Importation ---------------------------------------------------------------
import { OBSERVER } from './../main'
import { clickToScrollToBlock } from './../functions/functions'
// ---------------------------------------------------------------------------

export const backToTop = (options = {}) => {

  // Déclaration des propriétés par défaut
  options.container         === undefined ? options.container         = '[data-js="back-to-top-container"]' : options.container
  options.backToTopSelector === undefined ? options.backToTopSelector = '[data-js="back-to-top"]'           : options.backToTopSelector
  options.distance          === undefined ? options.distance          = 500                                 : options.distance
  options.showClass         === undefined ? options.showClass         = 'displayed'                         : options.showClass

  // Déclarations des variables
  const container = document.querySelector(options.container)

  // Déclarations des fonctions
  const manageBackToTop = () => document.documentElement.scrollTop >= options.distance ? container.classList.add(options.showClass) : container.classList.remove(options.showClass)
  
  // Si le container existe
  if (typeof(container) != 'undefined' && container != null) {

    // Permet de retourner en haut de la page
    clickToScrollToBlock({ selector: options.backToTopSelector })

    // Appel de la fonction au moins une fois
    manageBackToTop()
    
    // Ajout de l'événement pour masquer ou afficher le contenu
    OBSERVER.add({ name: 'manageBackToTop', event: 'scroll', function: manageBackToTop })
    OBSERVER.on('manageBackToTop')
  }
}