import barba from '@barba/core'
import { SCROLLFIRE, OBSERVER } from './main.js'
import { defaultTransition } from './transitions/default-transition.js'
import { backendKeyBind, calendar, clickToScrollToBlock, scrollToBlock, hideFastLinks, ogContent, slugifyProvider } from './functions/functions.js'
import { dynamicPackage } from './functions/dynamic.js'
import { resetDropzone, formsPackage, tailSelect, clearInput } from './functions/form.js'
import { overlayCalendar, overlayDropdown, overlayFastlinks, overlayMenu, overlayPopup, overlaySearch, overlayShare  } from './functions/overlays.js'
import { Accordions } from './classes/Accordions.js'
import Overlay from './vendor/overlay.js'
import { formContact, formExample, formJobs, formSuccess, formInfolettre } from './functions/validation.js'
import { hundredVH, removeHoverOnMobile, sizeTitle } from './functions/helper.js'
import { swiperAlert } from './functions/alert.js'
import { swiperHomeBanner } from './functions/home-banner.js'
import { swiperHomeNews } from './functions/home-news.js'
import { swiperHomeEvents } from './functions/home-events.js'
import { backToTop } from './functions/back-to-top-and-links.js'

window.barba = barba
window.formSuccess = formSuccess
window.resetDropzone = resetDropzone
window.slugifyProvider = slugifyProvider
window.scrollToBlock = scrollToBlock

export const initBarba = () => {

  barba.hooks.beforeEnter(({ current }) => {
    if (current.container != null)
      current.container.remove()

    removeHoverOnMobile()
    hundredVH()
    overlayShare()
    overlayDropdown()
    overlayMenu()
    overlayFastlinks()
    overlaySearch()
    overlayPopup()
    backendKeyBind()
    SCROLLFIRE.init()
    swiperAlert()
    backToTop()
    hideFastLinks()
    sizeTitle({
      selectors: "h1, .h1, .home-banner__text",
      caract01: "55",
      caract02: "65",
      caract03: "75"
    })
    clearInput()
    formsPackage()
    formInfolettre('#form-newsletter', '#submit-newsletter')
  })

  barba.hooks.enter ((data) => {
    ogContent(data)
  })

  barba.hooks.afterLeave (() => {
    OBSERVER.allOff()
    OBSERVER.removeAll()
    Overlay.destroyAll()
    SCROLLFIRE.destroy()
    window["swiper-alert"].destroy()
  })

  barba.init({
    cacheIgnore: true,
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),

    transitions: [
      defaultTransition()
    ],

    views: [
      {
        namespace: 'styleView',
        beforeEnter() {
          new Accordions()
          dynamicPackage()
          formExample()
        },
        afterLeave () {}
      },
      {
        namespace: 'detailView',
        beforeEnter() {
          new Accordions()
          dynamicPackage()
          formExample()
        },
        afterLeave () {}
      },
      {
        namespace: 'homeView',
        beforeEnter() {
          swiperHomeBanner()
          swiperHomeNews()
          swiperHomeEvents()
          clickToScrollToBlock({ selector: '[data-js="go-to-events"]', scrollTo: '[data-js="events"]' })
        },
        afterLeave () {
          window["swiper-home-banner"].destroy()
          window["swiper-home-news"].destroy()
          window["swiper-home-events"].destroy()
        }
      },
      {
        namespace: 'newsListView',
        beforeEnter() {
          tailSelect()
        },
        afterLeave () {}
      },
      {
        namespace: 'newsDetailView',
        beforeEnter() {
          dynamicPackage()
        },
        afterLeave () {}
      },
      {
        namespace: 'eventsListView',
        beforeEnter() {
          tailSelect()
          calendar()
          overlayCalendar()
        },
        afterLeave () {}
      },
      {
        namespace: 'eventsDetailView',
        beforeEnter() {
          dynamicPackage()
        },
        afterLeave () {}
      },
      {
        namespace: 'contactView',
        beforeEnter() {
          dynamicPackage()
          formContact()
          new Accordions()
          clickToScrollToBlock({ selector: '[data-js="go-to-form"]', scrollTo: '[data-js="form"]' })
        },
        afterLeave () {}
      },
      {
        namespace: 'searchResultsView',
        beforeEnter() {
          tailSelect()
        },
        afterLeave () {}
      },
      {
        namespace: 'jobsListView',
        beforeEnter() {
          formJobs()
        },
        afterLeave () {}
      },
      {
        namespace: 'jobsDetailView',
        beforeEnter() {
          formJobs()
        },
        afterLeave () {}
      },
    ]
  })
}

$( document ).ready(function() {
  document.body.style.opacity = '1'
})
